import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "homeView" */ '../views/HomeView.vue')
  },
  {
    path: '/survey/:id',
    name: 'DoSurvey',
    meta: {
      hideNav: true,
    },
    component: () => import(/* webpackChunkName: "doSurvey" */ '../views/DoSurvey.vue')
  },
  {
    path: '/projectFeedback/:id',
    name: 'ProjectFeedback',
    meta: {
      hideNav: true,
    },
    component: () => import(/* webpackChunkName: "ProjectFeedback" */ '../views/project/ProjectFeedback.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      hideNav: true,
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async(to, from, next) => {
  // console.log(`From ${from.name} to ${to.name}`);
  // console.log(`requiresAuth: ` +  to.matched.some((record) => record.meta.requiresAuth))
  // console.log(store.getters['auth/hasToken'])
  // await store.dispatch('pageData/setPageFrom', from.name)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/hasToken']) {
      next();
    } else if(store.getters['auth/hasCookie']) {
      try {
        await store.dispatch('auth/initLoginToken');
        next();
      } catch (error) {
        console.error(error);
        next({ name: 'Login' });
      }
    } else {
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});

router.afterEach(async(to) => {
  // console.log(`${JSON.stringify(to.meta)}`);
  let hideNav = false;
  if (to.meta.hideNav) {
    hideNav = true;
  }
  store.commit('SETNAVHIDE', hideNav);
});

export default router
